<template>
  <div>
    <!-- eslint-disable vue/no-mutating-props -->
    <v-dialog v-model="addSpeciesListDialog" persistent width="500" scrollable>
      <v-card>
        <header-view
          dialog
          previous_page="NO_BACK_BUTTON"
          :title="dialogTitle"
          closeBtn
          :closeAction="closeDialog" />
        <v-card-text>
          <div>
            <v-radio-group
              dense
              v-model="importType"
              row
              v-if="!showTable && !admin">
              <v-radio label="Factory" value="factory"></v-radio>
              <v-radio label="Upload" value="upload"></v-radio>
            </v-radio-group>
            <v-switch
              v-model="addVerified"
              :label="`Upload as Verified: ${addVerified.toString()}`"
              v-if="showTable && admin"
              color="button-primary"></v-switch>
            <v-autocomplete
              label="Region"
              :items="speciesRegions.slice(1)"
              v-if="showTable && admin"
              item-text="text"
              item-value="value"
              v-model="addRegion"
              clearable
              multiple
              chips></v-autocomplete>
          </div>
          <v-divider v-if="!admin && !showTable"></v-divider>
          <div v-if="!showTable">
            <!-- factory list selection (non-admin) -->
            <div
              style="height: 100px; margin-top: 2em"
              v-if="importType == 'factory' && !admin">
              <v-flex xs12>
                <v-select
                  :items="speciesRegions"
                  v-model="selectedRegion"
                  label="Region:"></v-select>
              </v-flex>
            </div>
            <!-- file upload section (non-admin) -->
            <div v-else style="height: 100px; margin-top: 2em">
              <v-form ref="fileUploadForm">
                <v-file-input
                  label="Upload Species .csv"
                  @change="handleFile"
                  v-model="file"
                  :rules="validate_file"
                  accept=".csv"></v-file-input>
              </v-form>
            </div>
          </div>
          <div v-else>
            <species-table
              selectable
              :admin="admin"
              :speciesList="speciesList"
              :loading="!speciesRead"
              @select-change="handleSelectChange"></species-table>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!--next or import is shown based on which step-->
          <v-btn class="button-primary" @click="loadList" v-if="!showTable"
            >Next</v-btn
          >
          <v-btn class="button-primary" @click="addSpeciesList" v-else
            >Add List</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- eslint-enable -->
    <add-species-status
      :show="showStatusDialog"
      :info="statusInfo"
      :loaded="speciesLoaded"
      :progress="uploadedSpeciesPercent"
      @close="
        showStatusDialog = false;
        speciesLoaded = false;
        statusInfo = {};
        speciesRead = false;
      "></add-species-status>
  </div>
</template>

<script>
  import axios from 'axios';
  import papa from 'papaparse';
  import SpeciesTable from '@/components/species/species-table';
  import SpeciesStatus from '@/components/species/add-species-status';
  import HeaderView from '@/components/header-view';
  import Species from '@/mixins/species';

  export default {
    name: 'AddSpeciesList',
    components: {
      'header-view': HeaderView,
      'species-table': SpeciesTable,
      'add-species-status': SpeciesStatus,
    },
    mixins: [Species],
    props: {
      admin: {
        default: false,
        type: Boolean,
      },
      addSpeciesListDialog: {
        default: false,
        type: Boolean,
      },
    },
    data() {
      return {
        //show available species to add
        showTable: false,
        //the available species to add
        tableSpecies: [],
        //the selected species to be added, sublist of tableSpecies
        selectedSpecies: [],
        selectedRegion: 'all',

        file: null,
        fileContents: {},
        validate_file: [(v) => !!v || 'This is required'],

        importType: 'factory',
        speciesList: [],
        addVerified: true,
        addRegion: [],
        showStatusDialog: false,
        statusInfo: {},
        speciesRead: false,
        speciesLoaded: false,
        params: {params: {region: 'all'}},
      };
    },
    computed: {
      // eslint-disable-next-line vue/return-in-computed-property
      dialogTitle: function () {
        if (this.admin) {
          return 'Upload to Master';
        } else {
          if (!this.showTable) {
            return 'Import Tree List';
          } else {
            for (var i = 0; i < this.speciesRegions.length; i++) {
              var list = this.speciesRegions[i];
              if (list.value == this.selectedRegion) {
                return list.text;
              }
            }
          }
        }
      },
      uploadedSpeciesPercent: function () {
        if (this.totalPosts && this.selectedSpecies.length > 0) {
          var ratio = 100 * (this.totalPosts / this.selectedSpecies.length);
          return ratio;
        } else {
          return 0;
        }
      },
    },
    methods: {
      closeDialog: function () {
        this.speciesList = [];
        this.selectedSpecies = [];
        this.showTable = false;
        this.file = null;
        this.fileContents = {};
        this.$emit('close');
      },
      loadList: function () {
        if (this.importType == 'factory' && !this.admin) {
          this.importFactoryList();
        } else {
          if (!this.$refs.fileUploadForm.validate()) {
            return false;
          }
          this.uploadList();
        }
        this.showTable = true;
      },
      async importFactoryList() {
        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();
        let url = '/service_tenants/master_species/';

        //hit master endpoint or region pick endpoint
        if (this.selectedRegion == 'all') {
          url += 'verified';
        } else {
          url += 'region/' + this.selectedRegion;
          this.params.params.region = this.selectedRegion;
        }

        //get chosen factory species list
        axios({
          method: 'GET',
          url: url,
          params: this.params,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }).then((response) => {
          if (response) {
            //load list into import selection data table
            this.speciesList = response.data;
            //set all trees to be selected by default
            this.selectedSpecies = this.speciesList.slice();
            this.speciesRead = true;
          }
        });
      },
      uploadList: function () {
        var self = this;
        papa.parse(this.fileContents, {
          header: true,
          worker: true,
          skipEmptyLines: true,
          complete: function (results) {
            self.fileContents = null;
            var latinAlias, commonAlias;
            for (var field in results.data[0]) {
              if (typeof results.data[0][field] == 'string') {
                var fl = field.toLowerCase();
                if (
                  fl.includes('latin') ||
                  fl.includes('scientific') ||
                  fl.includes('botanical')
                ) {
                  latinAlias = field;
                } else if (fl.includes('common')) {
                  commonAlias = field;
                }
              }
            }
            var speciesAliased = results.data.map((x) => {
              return {
                common_name: x[commonAlias],
                latin_name: x[latinAlias],
              };
            });
            self.speciesList = speciesAliased.filter(
              (x) => x.latin_name && x.common_name
            );
            self.speciesRead = true;
          },
          error: function (error) {
            console.log(error);
          },
        });
      },
      handleFile: function (file) {
        this.fileContents = file;
      },
      addSpeciesList: function () {
        this.$emit('close');
        this.showStatusDialog = true;
        if (this.admin) {
          // console.log('regions', this.addRegion)
          this.addMasterSpecies(
            this.selectedSpecies,
            this.addVerified,
            this.addRegion
          ).then((response) => {
            this.statusReturned(response);
          });
        } else {
          this.addTenantSpecies(this.selectedSpecies).then((response) => {
            this.statusReturned(response);
          });
        }
      },
      statusReturned: function (info) {
        this.closeDialog();
        this.statusInfo = info;
        this.speciesLoaded = true;
        this.$emit('list-added', this.$auth.userProfile.tenant_uuid);
      },
      handleSelectChange: function (event) {
        // console.log('list change', event)
        this.selectedSpecies = event.slice(0);
      },
    },
  };
</script>
<style scoped>
  .v-input--radio-group .v-input__control {
    width: 100% !important;
  }
</style>
