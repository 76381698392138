<template>
  <div>
    <v-layout>
      <v-flex :class="selectable ? 'xs12 md6' : 'xs6 md3'">
        <v-text-field
          append-icon="mdi-search"
          label="Search"
          v-model="speciesSearch"
          clearable></v-text-field>
      </v-flex>
      <v-flex :class="selectable ? 'xs12 md6' : 'xs6 md3'">
        <v-select
          v-model="speciesSearchColumn"
          :items="speciesSearchOptions"></v-select>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex xs6 md3 v-if="!selectable">
        <v-select
          v-model="speciesToggleFilter"
          :items="speciesToggleOptions"
          label="Show:"></v-select>
      </v-flex>
      <v-flex xs6 md3 v-if="!selectable && admin">
        <v-select
          :items="speciesRegions"
          v-model="speciesRegionFilter"
          label="Region:"></v-select>
      </v-flex>
    </v-layout>
    <v-data-table
      :headers="headers"
      :items="filteredSpecies"
      :search="speciesSearch"
      :show-select="selectable"
      :loading="loading"
      :items-per-page="10"
      loading-text="Loading Species..."
      v-model="selectedSpecies"
      sort-by="common_name"
      class="elevation-1"
      no-data-text="No species to display."
      item-key="latin_name"
      dense
      @click:row="selectRow">
      <!-- eslint-disable vue/valid-v-slot -->
      <template v-slot:item.common_name="props">
        <div
          v-html="searchHighlight(props.item.common_name, 'common_name')"></div>
      </template>
      <template v-slot:item.latin_name="props">
        <div
          v-html="searchHighlight(props.item.latin_name, 'latin_name')"></div>
      </template>
      <template v-if="!selectable" v-slot:item.toggleAttr="props">
        {{ props.item.toggleAttr ? 'Yes' : 'No' }}
      </template>
      <template v-slot:header.data-table-select="{on, props}">
        <v-checkbox
          color="blue"
          v-bind="props"
          v-on="on"
          @change="toggleAllSpecies"
          dense />
      </template>
      <!-- eslint-enable -->
    </v-data-table>
    <span v-if="selectable">{{ selectionMessage }}</span>
  </div>
</template>

<script>
  import Species from '@/mixins/species.js';
  export default {
    name: 'SpeciesTable',
    mixins: [Species],
    props: {
      admin: {
        default: false,
        type: Boolean,
      },
      speciesList: Array,
      selectable: {
        default: false,
        type: Boolean,
      },
      loading: {
        default: false,
        type: Boolean,
      },
    },
    data() {
      let toggleAttr = this.admin ? 'verified' : 'active';
      return {
        toggleAttr: toggleAttr,

        speciesSearch: '',
        //radio model for which column to search on
        speciesSearchColumn: 'both',
        speciesSearchOptions: [
          {
            text: 'by Common Name',
            value: 'common_name',
          },
          {
            text: 'by Latin Name',
            value: 'latin_name',
          },
          {
            text: 'by Common and Latin',
            value: 'both',
          },
        ],
        //select model for showing verified or unverified species
        speciesToggleFilter: 'all',
        speciesToggleOptions: [
          {
            text: 'All',
            value: 'all',
          },
          {
            text: this.capitalize(toggleAttr),
            value: toggleAttr,
          },
          {
            text: 'Not ' + this.capitalize(toggleAttr),
            value: 'not ' + toggleAttr,
          },
        ],

        speciesRegionFilter: 'all',

        selectedSpecies: [],
      };
    },
    computed: {
      filteredSpecies: function () {
        return this.speciesList.filter((species) => {
          //mapped to toggleAttr so vue slot can be used in table
          if (!species.toggleAttr) {
            species.toggleAttr = species[this.toggleAttr];
          }

          var toggleMatch = true;
          var searchMatch = true;
          var regionMatch = true;

          //filter items based on if they are verified or unverified, if all, nothing changes
          if (
            (this.speciesToggleFilter == this.toggleAttr &&
              !species[this.toggleAttr]) ||
            (this.speciesToggleFilter == 'not ' + this.toggleAttr &&
              species[this.toggleAttr])
          ) {
            toggleMatch = false;
          }

          if (
            this.admin &&
            !this.selectable &&
            this.speciesRegionFilter != 'all'
          ) {
            regionMatch = species.region.includes(this.speciesRegionFilter);
          }

          //filter items based off search query
          if (this.speciesSearch && toggleMatch && regionMatch) {
            var lowerSearch = this.speciesSearch.toLowerCase();

            //return matches for only latin or common name
            if (this.speciesSearchColumn != 'both') {
              searchMatch = species[this.speciesSearchColumn]
                .toLowerCase()
                .includes(lowerSearch);
              //return matches for both common and/or latin name
            } else {
              searchMatch =
                species['common_name'].toLowerCase().includes(lowerSearch) ||
                species['latin_name'].toLowerCase().includes(lowerSearch);
            }
          }

          return toggleMatch && searchMatch && regionMatch;
        });
      },
      selectionMessage: function () {
        var result = '';
        //get just the common names

        var commons = this.selectedSpecies
          ? this.selectedSpecies.map((x) => x.common_name)
          : [];
        if (commons.length > 5) {
          result =
            'Selected: ' +
            commons.slice(0, 5).join(', ') +
            ', and ' +
            (commons.length - 5) +
            ' others.';
        } else if (commons.length > 0) {
          result = 'Selected: ' + commons.join(', ') + '.';
        }
        return result;
      },
      headers: function () {
        var result = [
          {
            text: 'Common Name',
            value: 'common_name',
          },
          {
            text: 'Latin Name',
            value: 'latin_name',
          },
          {
            text: this.capitalize(this.toggleAttr),
            value: 'toggleAttr',
          },
          //{text: 'Details', value: 'uuid'}
        ];
        if (this.selectable) {
          return result.slice(0, 2);
        } else {
          return result;
        }
      },
    },
    watch: {
      //send events so selected species can be used by parent components
      selectedSpecies: {
        handler: function (val) {
          if (!(val === null)) {
            this.$emit('select-change', val);
          }
        },
        deep: true,
      },
      speciesList: {
        handler: function (val, oldVal) {
          console.log('species list added');
          if (oldVal.length == 0 && val.length > 0) {
            this.selectedSpecies = val.slice(0);
          }
        },
        deep: true,
      },
    },
    mounted() {},
    methods: {
      //highlight the search query within cells
      searchHighlight: function (text, column) {
        //only highlight if proper column is being searched on
        if (
          this.speciesSearch &&
          (column == this.speciesSearchColumn ||
            this.speciesSearchColumn == 'both')
        ) {
          //regex searching for the search query, gi means look for all occurances, i means case insensitive
          return text.replace(new RegExp(this.speciesSearch, 'gi'), (match) => {
            //wrap found query with span of a colored background
            return '<span style="background:lightgrey">' + match + '</span>';
          });
        } else {
          return text;
        }
      },
      capitalize: function (s) {
        return s
          .split(' ')
          .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(' ');
      },
      selectRow: function (item) {
        if (!this.selectable) {
          if (this.admin) {
            this.$router.push('/speciesAdmin/view/' + item.uuid);
          } else {
            this.$router.push('/species/view/' + item.tenantSpeciesUUID);
          }
        }
      },
      toggleAllSpecies: function () {
        // console.log( 'toggle all' )
        if (this.selectedSpecies.length < this.filteredSpecies.length) {
          this.selectedSpecies = this.filteredSpecies.slice(0);
        } else {
          this.selectedSpecies = [];
        }
      },
    },
  };
</script>
